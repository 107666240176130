import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 500;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  input[type="text"] {
  }
  span {
    color: ${({ theme }) => theme.colors.red};
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Functions = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
      gap: 0.25rem;
    }

    input[type="checkbox"]:checked {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 480px) {
    button {
      width: 8rem;
    }
  }
`;

export const Button = styled.button`
  width: 10rem;
  padding: 1rem;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red};

  &:hover {
    background-color: ${({ theme }) => theme.colors.light_red};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.dark_red};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  &.confirm {
    background-color: ${({ theme }) => theme.colors.blue};

    &:hover {
      background-color: ${({ theme }) => theme.colors.light_blue};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.dark_blue};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 500;
`;

export const Input = styled.input`
  &[type="text"] {
    width: 100%;
    padding: 1rem;
    font-size: 18px;
    font-weight: 500;
  }
`;
