export const defaultTheme = {
  colors: {
    Success: "#50be5b",
    Information: "#004EDA",
    Alert: "#ff9900",
    Error: "#ff003d",

    light_success: "#73d47a",
    dark_success: "#2d8a38",

    blue: "#004EDA",
    light_blue: "#2974FF",
    dark_blue: "#023695",

    red: "#FF0000",
    light_red: "#FF4949",
    dark_red: "#A20B0B",

    gray: "#808080",
    light_gray: "#cccccc",
    dark_gray: "#5A5A5F",

    text: "#808080",
    border: "#B6B6BB",
    background: "#f2f2f8",
    white: "#ffffff",
    black: "#000000",
  },
};
