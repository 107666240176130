import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { Container, Form, Functions, Buttons, Button, Label, Span, Input } from "./style";

import { api } from "../../../../services/api";
import { Modal } from "../../../../components/Modal";
import { BackButton } from "../../../../components/BackButton";
import { useWorkerContext } from "../../../../pages/Gamem/Workers/workerContext";
import { AlertComponent } from "../../../../components/AlertComponent";
import { WorkerActionKind } from "../../../../pages/Gamem/Workers/workerReducer";
import Skeleton from "react-loading-skeleton";

interface IErrorResponse {
  message: string;
}

interface WorkerData {
  id: number;
  email: string;
  function: Array<{
    id: string;
    name: string;
  }>;
  name: string;
  phone: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

const schema = yup.object().shape({
  name: yup.string().optional(),
  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Formato de email inválido")
    .optional(),
  phone: yup.string().min(16, "Número de telefone inválido").required("Campo obrigatório"),
  functionNames: yup
    .array()
    .transform((value, originalValue) => {
      if (originalValue && Array.isArray(originalValue)) {
        return originalValue.filter((val) => typeof val === "string");
      }
      return value;
    })
    .min(1, "Selecione pelo menos uma função")
    .optional(),
});

export function WorkersUpdate() {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDialogElement>(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");
  const { workers, dispatch } = useWorkerContext();
  const { id } = useParams();

  const worker = workers?.find((worker) => worker.id.toString() === id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<WorkerData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<WorkerData> = (WorkerData) => {
    setLoading(true);
    const workerPatch = {
      id: worker?.id,
      name: WorkerData.name,
      email: WorkerData.email,
      phone: WorkerData.phone,
      function: WorkerData.function,
    };
    api
      .patch("/worker/edit-worker", workerPatch)
      .then((response) => {
        showAlertMessage(response.data.name + " atualizado com sucesso!");
        setAlertType("Success");
        dispatch({
          type: WorkerActionKind.UPDATE_WORKER,
          payload: response.data,
        });
        setTimeout(() => {
          navigate("/trabalhadores");
        }, 1000);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const handleCancelChanges = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = "Editar Trabalhador";
  }, []);

  useEffect(() => {
    if (worker) {
      setValue("name", worker?.name);
      setValue("email", worker?.email);
      setValue("phone", worker?.phone);
      setIsLoading(false);
    }
  }, [setValue, worker]);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleCancelChanges}
        text="Tem certeza que deseja cancelar ?"
      />
      <BackButton />
      <h1>Editar Trabalhador</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Nome</Label>
        {isLoading ? (
          <Skeleton height={20} width={250} />
        ) : (
          <Input type="text" {...register("name")} defaultValue={worker?.name} />
        )}
        <Span>{errors?.name?.message}</Span>
        <Label>E-mail</Label>
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          <Input type="text" {...register("email")} defaultValue={worker?.email} />
        )}
        <Span>{errors?.email?.message}</Span>
        <Label>Telefone </Label>
        {isLoading ? (
          <Skeleton height={20} width={100} />
        ) : (
          <InputMask
            type="text"
            mask="(99) 9 9999-9999"
            maskPlaceholder={null}
            onChange={(e) => {
              setValue("phone", e.target.value);
            }}
            defaultValue={worker?.phone}
          >
            <Input placeholder="Digite aqui seu telefone" />
          </InputMask>
        )}
        <Span>{errors?.phone?.message}</Span>
        <Label>Funções</Label>
        <Functions>
          <Functions>
            <div>
              <Input
                type="checkbox"
                value="Atendente"
                {...register(`function.${0}`)}
                defaultChecked={worker?.function.some((f) => f.name === "Atendente")}
              />
              <Label>{isLoading ? <Skeleton width={150} /> : "Atendente"}</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                value="Coordenador"
                {...register(`function.${1}`)}
                defaultChecked={worker?.function.some((f) => f.name === "Coordenador")}
              />
              <Label>{isLoading ? <Skeleton width={150} /> : "Coordenador"}</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                value="Magnetizador"
                {...register(`function.${2}`)}
                defaultChecked={worker?.function.some((f) => f.name === "Magnetizador")}
              />
              <Label>{isLoading ? <Skeleton width={150} /> : "Magnetizador"}</Label>
            </div>
          </Functions>
        </Functions>
        <Span>{errors?.function?.message}</Span>

        {isLoading ? (
          <Buttons>
            <Skeleton height={50} width={100} />
            <Skeleton height={50} width={100} />
          </Buttons>
        ) : (
          <Buttons>
            <Button disabled={loading} type="button" onClick={handleOpenModal}>
              Cancelar
            </Button>
            <Button className="confirm" disabled={loading} type="submit">
              Salvar
            </Button>
          </Buttons>
        )}
      </Form>
    </Container>
  );
}
