import * as yup from "yup";
import { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { api } from "../../../services/api";
import { AlertComponent } from "../../../components/AlertComponent";
import { ReactComponent as SendIcon } from "../../../assets/icons/Send.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/Lock.svg";

import {
  Container,
  ContentWrapper,
  PatientForm,
  FirstInterview,
  SearchInput,
  List,
  Item,
  Label,
  Check,
  Attendant,
  Select,
  PatientInformation,
  Input,
  SpanError,
  TreatmentReasons,
  Reasons,
  InputWrapper,
  ComplaintsAndSymptoms,
  Textarea,
  TypeOfTreatment,
  Types,
  Radio,
  Button,
} from "./style";
import { useWorkerContext } from "../../../pages/Gamem/Workers/workerContext";
import { WorkerState } from "../../../pages/Gamem/Workers/workerReducer";
import { useListContext } from "../../../contexts/listContext";

interface IErrorResponse {
  message: string;
}

interface PatientData {
  attendantName: string;
  firstTime: boolean;
  patientName: string;
  patientPhone: string;
  patientYearsOld: number;
  reason1: string;
  reason2: string;
  reason3: string;
  reason4: string;
  symptoms: string;
  treatmentType: "M1" | "M2";
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

const schema = yup.object().shape({
  patientName: yup.string().required("Campo obrigatório"),
  patientPhone: yup.string().test("isRequired", "Campo obrigatório", function (value) {
    const { firstTime } = this.parent;
    if (firstTime) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  patientYearsOld: yup
    .number()
    .positive()
    .typeError("Digite uma idade válida")
    .min(0, "Idade mínima é 0")
    .test("isRequired", "Campo obrigatório", function (value) {
      const { firstTime } = this.parent;
      if (firstTime) {
        return yup.number().required().isValidSync(value);
      }
      return true;
    }),
  attendantName: yup.string().optional(),
  firstTime: yup.boolean(),
  reason1: yup.string().required("Campo obrigatório").nullable(),
  reason2: yup.string().optional().nullable(),
  reason3: yup.string().optional().nullable(),
  reason4: yup.string().optional().nullable(),
  symptoms: yup.string().required("Campo obrigatório"),
  treatmentType: yup.string().oneOf(["M1", "M2"]).required("Campo obrigatório"),
});

export function Form() {
  const { patients } = useListContext();
  const { workers } = useWorkerContext();
  const [attendant, setAttendant] = useState<Array<WorkerState>>();
  const [isFocusedInputName, setIsFocusedInputName] = useState(false);
  const [patientsList, setPatientsList] = useState<Array<string>>();
  const [searchList, setSearchList] = useState<Array<string>>();
  const [reasonTreatment, setReasonTreatment] = useState<Array<string>>();
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<PatientData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PatientData> = (PatientData) => {
    setLoading(true);
    const formRequest = {
      attendantName: PatientData.attendantName,
      firstTime: PatientData.firstTime,
      patientName: validaNomePaciente(),
      reason1: PatientData.reason1,
      reason2: PatientData.reason2,
      reason3: PatientData.reason3,
      reason4: PatientData.reason4,
      symptoms: PatientData.symptoms,
      treatmentType: PatientData.treatmentType,
    };
    api
      .post("/patient/send-form", formRequest, {
        params: {
          patientPhone: PatientData.patientPhone,
          patientYearsOld: PatientData.patientYearsOld,
        },
      })
      .then((response) => {
        showAlertMessage(`${response.data}`);
        setAlertType("Success");
        resetForm();
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const handleFocusChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocusedInputName(event.type === "focus");

    findPatientName();
  };

  function findPatientName() {
    let patientName = getValues("patientName");

    const searchListFiltered = patientsList?.find((name) => name === patientName);

    if (!searchListFiltered) {
      setReasonTreatment([]);
      setReasons([]);
      return;
    }
  }

  const searchPatient = (Patient: string) => {
    setValue("patientName", Patient);

    if (Patient === "") {
      setSearchList(patients);
      setReasonTreatment([]);
      setReasons([]);
      return;
    }

    const searchListFiltered = patientsList?.filter((patient) =>
      patient.toLowerCase().startsWith(Patient.toLowerCase())
    );

    setSearchList(searchListFiltered);
  };

  const handleClickPatient = (Patient: string) => {
    setValue("patientName", Patient);
    setSearchList([]);

    api
      .get("/patient/reasons-treatment", {
        params: {
          patientName: Patient,
        },
      })
      .then((response) => {
        setReasonTreatment(response.data);
        setReasons(response.data);
      });
  };

  const setReasons = (Reasons: string[]) => {
    setValue("reason1", Reasons[0]);
    setValue("reason2", Reasons[1]);
    setValue("reason3", Reasons[2]);
    setValue("reason4", Reasons[3]);
  };

  const handleClickCheck = () => {
    reset();
    setValue("firstTime", !firstTime);
    setFirstTime(!firstTime);
    setReasonTreatment([]);
  };

  const resetForm = () => {
    reset();
    setValue("firstTime", firstTime);
    setFirstTime(firstTime);
    setReasonTreatment([]);
    setSearchList([]);
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  function validaNomePaciente() {
    const patientName = getValues("patientName");
    let separadas = patientName.split(" ").filter((n) => n !== "");
    let nome = separadas[0];
    for (var i = 1; i < separadas.length; i++) {
      nome += " " + separadas[i];
    }
    return nome;
  }

  useEffect(() => {
    setPatientsList(patients);
    setSearchList(patients);
  }, [patients]);

  useEffect(() => {
    const attendants = workers.filter((worker) =>
      worker?.function?.find((f) => f.name === "Atendente")
    );
    setAttendant(attendants);
  }, [workers]);

  useEffect(() => {
    document.title = "Formulário";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <ContentWrapper>
        <PatientForm onSubmit={handleSubmit(onSubmit)}>
          <FirstInterview>
            <Label>1° vez</Label>
            <Check type="checkbox" {...register("firstTime")} onClick={handleClickCheck} />
            <SpanError>{errors.firstTime?.message}</SpanError>
          </FirstInterview>

          <Attendant>
            <Label>Atendente Fraterno</Label>
            <Select {...register("attendantName")}>
              <option value="Sem Atendimento">Sem Atendimento</option>
              {attendant?.map((attendant) => {
                return (
                  <option key={attendant.id} value={attendant.name}>
                    {attendant.name}
                  </option>
                );
              })}
            </Select>
            <SpanError>{errors.attendantName?.message}</SpanError>
          </Attendant>

          <PatientInformation>
            <Label>Nome*</Label>
            {firstTime ? (
              <Input type="text" placeholder="Digite aqui o nome" {...register("patientName")} />
            ) : (
              <SearchInput>
                <Input
                  type="text"
                  placeholder="Digite aqui o nome"
                  autoComplete="off"
                  {...register("patientName")}
                  onFocus={handleFocusChange}
                  onBlur={handleFocusChange}
                  onChange={(e) => {
                    searchPatient(e.target.value);
                  }}
                />
                <SearchIcon />
                {isFocusedInputName && (
                  <List>
                    {searchList &&
                      searchList.map((patient, index) => {
                        return (
                          <Item
                            key={patient + index}
                            onMouseDown={() => {
                              handleClickPatient(patient);
                            }}
                          >
                            {patient}
                          </Item>
                        );
                      })}
                  </List>
                )}
              </SearchInput>
            )}
            <SpanError>{errors.patientName?.message}</SpanError>
          </PatientInformation>

          <PatientInformation>
            {firstTime && (
              <>
                <Label>Telefone*</Label>
                <InputMask
                  type="tel"
                  mask="(99) 9 9999-9999"
                  maskPlaceholder={null}
                  {...register("patientPhone")}
                  onChange={(e) => {
                    setValue("patientPhone", e.target.value);
                    trigger("patientPhone");
                  }}
                >
                  <Input placeholder="Digite aqui o telefone" />
                </InputMask>
                <SpanError>{errors.patientPhone?.message}</SpanError>
                <Label>Idade*</Label>
                <Input
                  type="number"
                  min={0}
                  placeholder="Digite aqui a idade"
                  {...register("patientYearsOld")}
                />
                <SpanError>{errors.patientYearsOld?.message}</SpanError>
              </>
            )}
          </PatientInformation>

          {!firstTime && reasonTreatment && reasonTreatment?.length > 0 ? (
            <TreatmentReasons>
              <Reasons>
                <Label>Motivo do Tratamento</Label>
                {reasonTreatment && reasonTreatment[0] !== null && reasonTreatment[0] !== "" && (
                  <InputWrapper>
                    <Input type="text" disabled {...register("reason1")} />
                    <LockIcon />
                  </InputWrapper>
                )}
                {reasonTreatment && reasonTreatment[1] !== null && reasonTreatment[1] !== "" && (
                  <InputWrapper>
                    <Input type="text" disabled {...register("reason2")} />
                    <LockIcon />
                  </InputWrapper>
                )}
                {reasonTreatment && reasonTreatment[2] !== null && reasonTreatment[2] !== "" && (
                  <InputWrapper>
                    <Input type="text" disabled {...register("reason3")} />
                    <LockIcon />
                  </InputWrapper>
                )}
                {reasonTreatment && reasonTreatment[3] !== null && reasonTreatment[3] !== "" && (
                  <InputWrapper>
                    <Input type="text" disabled {...register("reason4")} />
                    <LockIcon />
                  </InputWrapper>
                )}
              </Reasons>
            </TreatmentReasons>
          ) : (
            <TreatmentReasons>
              <Label>Motivo do Tratamento</Label>
              <Reasons>
                <Input type="text" placeholder="Motivo 1 *" {...register("reason1")} />
                <SpanError>{errors.reason1?.message}</SpanError>
                <Input type="text" placeholder="Motivo 2" {...register("reason2")} />
                <SpanError>{errors.reason2?.message}</SpanError>
                <Input type="text" placeholder="Motivo 3" {...register("reason3")} />
                <SpanError>{errors.reason3?.message}</SpanError>
                <Input type="text" placeholder="Motivo 4" {...register("reason4")} />
                <SpanError>{errors.reason4?.message}</SpanError>
              </Reasons>
            </TreatmentReasons>
          )}

          <ComplaintsAndSymptoms>
            <Label>Queixas e sintomas*</Label>
            <Textarea
              rows={8}
              placeholder="Digite aqui as queixas e os sintomas"
              {...register("symptoms")}
            />
            <SpanError>{errors.symptoms?.message}</SpanError>
          </ComplaintsAndSymptoms>

          <TypeOfTreatment className="treatment">
            <Label>Tipo de Tratamento*</Label>
            <Types>
              <Label>M1</Label>
              <Radio type="radio" value="M1" {...register("treatmentType")} defaultChecked />
              <Label>M2</Label>
              <Radio type="radio" {...register("treatmentType")} value="M2" />
            </Types>
            <SpanError>{errors.treatmentType?.message}</SpanError>
          </TypeOfTreatment>

          <Button className="button" type="submit" disabled={loading}>
            Enviar Formulário
            <SendIcon />
          </Button>
        </PatientForm>
      </ContentWrapper>
    </Container>
  );
}
