import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.2));
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;

  transition: all 500ms ease;

  &.isShown {
    min-width: 240px;
    width: 240px;
    nav {
      width: 100%;
      transform: translateX(0);
      a {
        width: 100%;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &.not_isShown {
    min-width: 0;
    width: 0;
    nav {
      width: 0;
      opacity: 0;

      a {
        width: 0;
        opacity: 0;
      }
    }
  }

  @media (max-width: 768px) {
    position: absolute;
  }
`;

export const Nav = styled.nav`
  cursor: pointer;
  transition: 300ms transform;
  transform: translateX(-400%);

  a {
    transition: 300ms transform;
    transform: translateX(-400%);
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dark_gray};
    &.active {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue};
      svg path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.light_blue};
      svg path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }

    &:active {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.dark_blue};
      svg path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
  }
  a:last-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }
`;
