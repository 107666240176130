import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
  }

  a {
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ul, ol {
    list-style: none;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, input, textarea, label, a, button, td, th, li{
    font-family: 'Poppins', sans-serif;
  }

  p, span, a {
    color: ${({ theme }) => theme.colors.gray};
  }

  input, textarea {
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid ${({ theme }) => theme.colors.border};
  }

  input:focus-visible {
    outline-color: ${({ theme }) => theme.colors.blue};
  }

  button, input, textarea {
    border-radius: .25rem;
  }

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    :checked {
      background-color: ${({ theme }) => theme.colors.blue};
    }
    :checked::before {
      content: "✓";
      font-size: 12px;
      color: ${({ theme }) => theme.colors.white};
    }
  }
  
  input[type="radio"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    :checked {
      border-color: ${({ theme }) => theme.colors.blue};
    }
    :checked::before {
      content: "⬤";
      font-size: 12px;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  input::placeholder, textarea::placeholder{
    color: ${({ theme }) => theme.colors.light_gray};
  }

  section, select {
    border-radius: 0.5rem;
  }

  select {
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    padding: 0.5rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 78, 218, 0.18);
    border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 78, 218, 0.32);
    border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 78, 218, 0.5);
  }
`;
