import React from "react";
import { Container, Item, Button } from "./style";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/ArrowRight.svg";

interface Props {
  total: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const MAX_ITEMS = 5;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

export const Pagination: React.FC<Props> = ({ total, currentPage, setCurrentPage }) => {
  const maxFirst = Math.max(total - (MAX_ITEMS - 1), 1);
  const first = Math.min(Math.max(currentPage - MAX_LEFT, 1), maxFirst);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Item>
        <Button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0 || total === 0}
        >
          <ArrowLeftIcon />
        </Button>
      </Item>
      {Array.from({ length: Math.min(MAX_ITEMS, total) })
        .map((_, index) => index + first)
        .map((page) => (
          <Item key={page}>
            <Button
              className={page === currentPage + 1 ? "active" : ""}
              onClick={() => onPageChange(page - 1)}
            >
              {page}
            </Button>
          </Item>
        ))}
      <Item>
        <Button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage + 1 === total || total === 0}
        >
          <ArrowRightIcon />
        </Button>
      </Item>
    </Container>
  );
};
