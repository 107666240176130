import styled from "styled-components";

export const Container = styled.div`
  margin: 3rem 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 768px) {
    margin: 1rem;
    gap: 0.5rem;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

export const Functions = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.25rem;
  }
`;
