import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Container, Span } from "./style";
import { formatDate } from "../../hooks/formatDate";

interface DataEntry {
  data: Array<{
    date: string;
    noteWeek: number;
  }>;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function Graphic({ data }: DataEntry) {
  let labels = data.map((entry) => formatDate(entry.date));
  const values = data.map((entry) => entry.noteWeek);

  let barPercentage = 0.1;
  let displayXLabels = true;

  if (window.innerWidth <= 768) {
    barPercentage = 0.25;
    displayXLabels = false;
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: "Nota da Semana",
        data: values,
        backgroundColor: "#004EDA",
        barPercentage,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Gráfico de Notas",
      },
    },
    scales: {
      x: {
        display: displayXLabels,
        grid: {
          display: window.innerWidth <= 768 ? false : true,
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <>
      {data.length > 0 ? (
        <Container>
          <Bar data={chartData} options={options} />
        </Container>
      ) : (
        <Span>Não há notas.</Span>
      )}
    </>
  );
}
