import styled from "styled-components";

interface AlertTypeProps {
  type: "Success" | "Error" | "Alert" | "Information";
}

export const Container = styled.div<AlertTypeProps>`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  pointer-events: none;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    top: 10%;
    width: 90%;
  }

  border-radius: 0.25rem;
  background-color: ${({ theme, type }) =>
    type === "Success"
      ? theme.colors.Success
      : type === "Error"
      ? theme.colors.Error
      : type === "Alert"
      ? theme.colors.Alert
      : type === "Information"
      ? theme.colors.Information
      : theme.colors.background};

  filter: drop-shadow(4px 0px 16px rgba(0, 0, 0, 0.25));

  &.visible {
    opacity: 1;
  }

  span {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }
`;
