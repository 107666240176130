export enum PatientActionKind {
  SET_PATIENT = "SET_PATIENT",
  DELETE_PATIENT = "DELETE_PATIENT",
}

export interface PatientAction {
  type: PatientActionKind;
  payload: Array<PatientState> | PatientState | Number;
}

export interface PatientState {
  id: number;
  name: string;
  phone: string;
}

export const PatientReducer = (state: Array<PatientState> | [], action: PatientAction) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_PATIENT":
      // Preenche a lista com os pacientes
      if (Array.isArray(payload)) {
        return payload;
      }
      return state;
    case "DELETE_PATIENT":
      // Remover um paciente da lista
      if (!Array.isArray(payload)) {
        return state.filter((item) => item.id !== payload);
      }
      return state;
    default:
      return state;
  }
};
