import * as yup from "yup";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { FormData, Treatment, GraphicData, CardProps } from "./interface";

import { api } from "../../../../services/api";
import { AlertComponent } from "../../../../components/AlertComponent";
import { BackButton } from "../../../../components/BackButton";
import { SearchBar } from "../../../../components/SearchBar";
import { Pagination } from "../../../../components/Pagination";

import {
  Container,
  Header,
  InfoWrapper,
  Patient,
  Info,
  Label,
  Span,
  TreatmentReasons,
  Reasons,
  CardWrapper,
  CardContainer,
  DateList,
  Select,
  GraphicWrapper,
} from "./style";
import { Graphic } from "../../../../components/Graphic";
import { useWorkerContext } from "../../../../pages/Gamem/Workers/workerContext";
import { WorkerState } from "../../../../pages/Gamem/Workers/workerReducer";
import { formatDate } from "../../../../hooks/formatDate";
import { FichaSalva } from "./components/fichasalva";
import { EditarFicha } from "./components/editarficha";
import { SalvarFicha } from "./components/salvarficha";

interface IErrorResponse {
  message: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

export const schema = yup.object().shape({
  magnetizerName: yup.string().required("Campo obrigatório"),
  noteWeek: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue.trim() === "" ? undefined : value;
    })
    .required("Campo obrigatório"),
  procedure: yup.string().required("Campo obrigatório"),
  treatmentType: yup
    .string()
    .oneOf(["M1", "M2"])
    .test("isRequired", "Campo obrigatório", function (value) {
      const { editingTreatment } = this.parent;
      if (editingTreatment) {
        return yup.string().required("Campo obrigatório").isValidSync(value);
      }
      return true;
    }),
  symptoms: yup.string().test("isRequired", "Campo obrigatório", function (value) {
    const { editingTreatment } = this.parent;
    if (editingTreatment) {
      return yup.string().required("Campo obrigatório").isValidSync(value);
    }
    return true;
  }),
  editingTreatment: yup.boolean(),
});

export function TreatmentInfo() {
  const methods = useForm<FormData>();

  const navigate = useNavigate();
  const { patientName } = useParams();
  const { workers } = useWorkerContext();
  const [magnetizer, setMagnetizer] = useState<Array<WorkerState>>();

  const [numberWeeks, setNumberWeeks] = useState(4);
  const [graphicData, setGraphicData] = useState<Array<GraphicData>>([]);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const [editingTreatment, setEditingTreatment] = useState(false);
  const [card, setCard] = useState<CardProps>({} as CardProps);
  const [cardId, setCardId] = useState<number>(0);
  const [cardDate, setCardDate] = useState<string>();
  const [treatment, setTreatment] = useState<Treatment>();
  const [currentPage, setCurrentPage] = useState(0);

  const { setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = (FormData) => {
    const cardRequest = {
      cardId: cardId,
      magnetizerName: FormData.magnetizerName,
      noteWeek: FormData.noteWeek,
      procedure: FormData.procedure,
    };

    api
      .post("/patient/fill-card", cardRequest)
      .then((response) => {
        showAlertMessage(`${response.data}`);
        setAlertType("Success");
        updateCardAfterSubmit(FormData);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  };

  const onSubmitEditTreatment: SubmitHandler<FormData> = (FormData) => {
    const cardRequest = {
      id: cardId,
      magnetizerName: FormData.magnetizerName,
      noteWeek: FormData.noteWeek,
      procedure: FormData.procedure,
      symptoms: FormData.symptoms,
      treatmentType: FormData.treatmentType,
    };

    api
      .put("/card", cardRequest)
      .then(() => {
        showAlertMessage("Card editado com sucesso!");
        setAlertType("Success");
        salvarCard(cardRequest);
        setEditingTreatment(false);
        setValue("editingTreatment", false);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  };

  useEffect(() => {
    const magnetizers = workers.filter((worker) =>
      worker.function.find((f) => f.name === "Magnetizador")
    );
    setMagnetizer(magnetizers);
  }, [workers]);

  // Atualiza o card de tratamento
  useEffect(() => {
    if (treatment) {
      api
        .get<CardProps>("/patient/card-by-date", {
          params: {
            date: cardDate,
            treatmentId: treatment?.id,
          },
        })
        .then((response) => {
          setCard(response.data);
          setCardId(response.data.id);
          setEditingTreatment(false);
          setValue("editingTreatment", false);
        });
    }
  }, [treatment?.id, cardDate]);

  // Atualiza a data do card
  useEffect(() => {
    setCardDate(treatment?.dateList[currentPage]);
  }, [currentPage]);

  // Pega o tratamento do paciente
  useEffect(() => {
    api
      .get("/patient/treatment", {
        params: {
          patientName: patientName,
        },
      })
      .then((response) => {
        setTreatment(response.data);
        setCardDate(response.data.dateList[currentPage]);
      });
  }, []);

  // Atualiza o gráfico de notas
  useEffect(() => {
    if (treatment?.id) {
      api
        .get("/patient/treatment-graphic", {
          params: {
            numberWeeks: numberWeeks,
            treatmentId: treatment?.id,
          },
        })
        .then((response) => {
          setGraphicData(response.data);
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    }
  }, [treatment?.id, numberWeeks]);

  useEffect(() => {
    document.title = "Tratamento";
  }, []);
  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Header>
        <div className="backbutton">
          <BackButton />
        </div>
        <SearchBar type="Treatment" onSearch={handleSearch} />
      </Header>
      <InfoWrapper>
        <Patient>
          <div className="backbutton">
            <BackButton />
          </div>
          <Info>
            <Label>Nome</Label>
            <Span>{patientName}</Span>
          </Info>
          <Info>
            <Label>Último Atendimento</Label>
            <Span>{treatment && formatDate(treatment?.lastTreatment)}</Span>
          </Info>
        </Patient>
        <TreatmentReasons>
          <Label>Motivo do Tratamento:</Label>
          <Reasons>
            <Span>{treatment?.reason1}</Span>
            <Span>{treatment?.reason2}</Span>
            <Span>{treatment?.reason3}</Span>
            <Span>{treatment?.reason4}</Span>
          </Reasons>
        </TreatmentReasons>
      </InfoWrapper>
      <CardContainer>
        <FormProvider {...methods}>
          <CardWrapper
            onSubmit={methods.handleSubmit(editingTreatment ? onSubmitEditTreatment : onSubmit)}
          >
            <DateList>
              <Select value={cardDate} onChange={(e) => handleSelectCardByDate(e.target.value)}>
                {treatment?.dateList.map((date, index) => {
                  return (
                    <option key={date + index} value={date}>
                      {formatDate(date)}
                    </option>
                  );
                })}
              </Select>
            </DateList>
            {getCard()}
          </CardWrapper>
        </FormProvider>
        {treatment && (
          <Pagination
            total={treatment?.dateList.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardContainer>
      <GraphicWrapper>
        <Select
          style={{ maxWidth: "10rem" }}
          defaultValue={4}
          onChange={(e) => {
            setNumberWeeks(parseInt(e.target.value));
          }}
        >
          <option value={4}>4 Semanas</option>
          <option value={8}>8 Semanas</option>
          <option value={12}>12 Semanas</option>
        </Select>
        {graphicData && <Graphic data={graphicData} />}
      </GraphicWrapper>
    </Container>
  );

  // Atualizar notas no gráfico
  function updateCardAfterSubmit(FormData: FormData) {
    card.magnetizerName = FormData.magnetizerName;
    card.noteWeek = FormData.noteWeek;
    card.procedure = FormData.procedure;
    card.editable = false;

    methods.setValue("cardId", card.id);
    methods.setValue("magnetizerName", card.magnetizerName);
    methods.setValue("noteWeek", card.noteWeek);
    methods.setValue("procedure", card.procedure);

    const graphic = {
      date: card.date,
      noteWeek: card.noteWeek,
    };
    const newGraphicData = graphicData;
    newGraphicData.unshift(graphic);
    setGraphicData(newGraphicData);
  }

  function showAlertMessage(message: string) {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  }

  function handleSelectCardByDate(Value: string) {
    const dateIndex = treatment?.dateList.findIndex((value) => value === Value);
    if (dateIndex !== undefined) {
      setCurrentPage(dateIndex);
    }
  }

  function editarTratamento() {
    setValue("editingTreatment", !editingTreatment);
    setEditingTreatment(!editingTreatment);
    setCard(() => {
      card.editable = !card.editable;
      return card;
    });

    methods.setValue("cardId", card.id);
    methods.setValue("magnetizerName", card.magnetizerName);
    methods.setValue("noteWeek", card.noteWeek);
    methods.setValue("procedure", card.procedure);
    methods.setValue("symptoms", card.symptoms);
    methods.setValue("treatmentType", card.treatmentType);
  }

  function salvarCard(card: FormData | any) {
    setCard((prevState) => {
      prevState.magnetizerName = card.magnetizerName;
      prevState.noteWeek = card.noteWeek;
      prevState.procedure = card.procedure;
      prevState.symptoms = card.symptoms;
      prevState.treatmentType = card.treatmentType;
      prevState.editable = false;
      return prevState;
    });
  }

  function handleSearch(value: string) {
    setCurrentPage(0);
    navigate(`/tratamentos/${value}`);
  }

  function getCard() {
    switch (card.editable) {
      case true:
        if (editingTreatment)
          return <EditarFicha data={card} magnetizer={magnetizer} onClick={editarTratamento} />;
        else return <SalvarFicha data={card} magnetizer={magnetizer} />;
      default:
        return <FichaSalva data={card} onClick={editarTratamento} />;
    }
  }
}
