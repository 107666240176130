import Skeleton from "react-loading-skeleton";
import { Container, Header, Reasons } from "./style";

export function CardTreatmentSkeleton() {
  return (
    <Container>
      <Header>
        <Skeleton />
        <Skeleton />
      </Header>
      <Skeleton width={"75%"} />
      <Reasons>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Reasons>
    </Container>
  );
}
