import Axios from "axios";

const defaultInterceptor = {
  response: async (config: any) => {
    const token = localStorage.getItem("@gamem:token");

    if (token) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("@gamem:token")}`;
    }

    return config;
  },

  error: (error: any) => {
    return Promise.reject(error);
  },
};

export const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(defaultInterceptor.response, defaultInterceptor.error);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (errors) => {
    const error = errors.response?.status;

    if (error === 401) {
      localStorage.removeItem("@gamem:token");
      localStorage.removeItem("@gamem:username");

      alert("Sessão Expirada! Por favor, faça login novamente.");
      window.location.href = "/";
    } else if (error === 403) {
      localStorage.removeItem("@gamem:token");
      localStorage.removeItem("@gamem:username");

      alert("Não Autorizado! Por favor, faça login novamente.");
      window.location.href = "/";
    }
    return Promise.reject(errors);
  }
);
