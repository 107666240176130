import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const THead = styled.thead`
  font-weight: 700;
  tr > td {
    cursor: initial;
  }
`;

export const TBody = styled.tbody`
  color: ${({ theme }) => theme.colors.text};
  tr {
    :hover {
      background-color: ${({ theme }) => theme.colors.background};
    }
    td:not(:last-of-type) {
      cursor: pointer;
    }
  }
`;

export const TRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  td:first-child {
    padding-left: 2em;
  }

  td:last-child {
    padding-right: 2em;
  }

  @media (max-width: 480px) {
    td:first-child {
      padding-left: 0.5em;
    }

    td:last-child {
      padding-right: 0.5em;
    }
  }
`;

export const TCell = styled.td`
  max-width: 100px;
  padding: 1rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :not(:first-of-type) {
    text-align: center;
  }

  &.not-show {
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 480px) {
    :last-of-type {
      max-width: 75px;
    }
  }

  &.lastTreatment {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &.lastTreatment-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

export const Button = styled.button`
  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.light_success};
    }
  }

  &:active {
    svg path {
      stroke: ${({ theme }) => theme.colors.dark_success};
    }
  }

  &.archive {
    &:hover {
      svg path {
        stroke: ${({ theme }) => theme.colors.light_red};
      }
    }

    &:active {
      svg path {
        stroke: ${({ theme }) => theme.colors.dark_red};
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;
