import styled from "styled-components";

export const Container = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1rem 0;
`;

export const Item = styled.li`
  max-width: 100%;
  + li {
    margin-left: 0.25rem;
  }
`;

export const Button = styled.button`
  height: 100%;
  padding: 0.5rem 0.75rem;
  color: ${({ theme }) => theme.colors.dark_gray};

  &.active {
    background: none;
    border: none;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue};
  }
  svg {
    width: 1rem;
    height: 100%;
  }
  :not(.active):not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.light_gray};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
`;
