import React, { useEffect, useRef, useState } from "react";
import { Container, InputWrapper, Input, Button, List, Item } from "./style";

import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/X.svg";
import { useListContext } from "../../contexts/listContext";

interface Props {
  type: "Patient" | "Treatment";
  onSearch: (value: string) => void;
}

export const SearchBar: React.FC<Props> = ({ type, onSearch }: Props) => {
  const { patients, treatments } = useListContext();
  const [searchList, setSearchList] = useState<Array<string>>();
  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = () => {
    onSearch(searchValue);
    inputRef.current?.blur();
  };

  const handleClickCancel = () => {
    setSearchValue("");
    setSearchList(type === "Patient" ? patients : treatments);
    inputRef.current?.focus();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    const searchListFiltered =
      type === "Patient"
        ? patients?.filter((patient) => patient.toLowerCase().startsWith(value.toLowerCase()))
        : treatments?.filter((treatment) =>
            treatment.toLowerCase().startsWith(value.toLowerCase())
          );

    setSearchList(searchListFiltered || []);
  };

  useEffect(() => {
    setSearchList(type === "Patient" ? patients : treatments);
  }, [patients, treatments, type]);

  return (
    <Container>
      <InputWrapper>
        <SearchIcon />
        <Input
          ref={inputRef}
          autoComplete="off"
          type="text"
          placeholder={type === "Patient" ? "Pesquisar paciente" : "Pesquisar Tratamento"}
          value={searchValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        />
        {searchValue && <CancelIcon onClick={handleClickCancel} />}
        {isFocused && (
          <List>
            {searchList &&
              searchList.map((item, index) => {
                return (
                  <Item
                    key={item + index}
                    onMouseDown={() => {
                      onSearch(item);
                    }}
                  >
                    {item}
                  </Item>
                );
              })}
          </List>
        )}
      </InputWrapper>
      <Button type="button" onClick={handleSearch}>
        Buscar
      </Button>
    </Container>
  );
};
