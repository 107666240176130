import { useEffect } from "react";
import { Container } from "./style";

import { ReactComponent as SuccessIcon } from "../../assets/icons/Success.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Error.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/Alert.svg";
import { ReactComponent as InformationIcon } from "../../assets/icons/Information.svg";

interface AlertProps {
  message: string;
  id: string;
  type: "Success" | "Error" | "Alert" | "Information";
}

export function AlertComponent({ message, id, type }: AlertProps) {
  const alertType = (type: AlertProps["type"]) => {
    let title = `Ícone de ${type} do Componente Alerta`;

    switch (type) {
      case "Success":
        return <SuccessIcon title={title} />;
      case "Error":
        return <ErrorIcon title={title} />;
      case "Alert":
        return <AlertIcon title={title} />;
      case "Information":
        return <InformationIcon title={title} />;
    }
  };

  useEffect(() => {
    if (message !== "") {
      const alertElement = document.getElementById("alertComponent");
      if (alertElement) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        alertElement.classList.add("visible");
        setTimeout(() => {
          alertElement.classList.remove("visible");
        }, 3000);
      }
    }
  }, [message]);

  return (
    <Container id={id} type={type}>
      {alertType(type)}
      <span>{message}</span>
    </Container>
  );
}
