import * as yup from "yup";
import { AxiosError } from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { ReactComponent as EyeOnIcon } from "../../../../assets/icons/EyeOn.svg";
import { ReactComponent as EyeOffIcon } from "../../../../assets/icons/EyeOff.svg";
import {
  Container,
  Form,
  Label,
  Input,
  SpanError,
  ButtonWrapper,
  Button,
  ShowEyeButton,
  InputWrapper,
} from "./style";

import { api } from "../../../../services/api";
import { Modal } from "../../../../components/Modal";
import { BackButton } from "../../../../components/BackButton";
import { AlertComponent } from "../../../../components/AlertComponent";

interface IErrorResponse {
  message: string;
}

interface UpdateData {
  userName: string;
  key: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

const schema = yup.object().shape({
  userName: yup.string().required("Campo obrigatório"),
  key: yup.string().required("Campo obrigatório"),
});

export function SettingsUsername() {
  const modalRef = useRef<HTMLDialogElement>(null);
  const navigate = useNavigate();
  const [showKey, setShowKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<UpdateData> = (UpdateData) => {
    setLoading(true);
    const admRequest = {
      userName: UpdateData.userName,
      key: UpdateData.key,
    };
    api
      .put("/adm/edit-profile", admRequest)
      .then(() => {
        localStorage.removeItem("@gamem:token");
        localStorage.removeItem("@gamem:username");
        showAlertMessage("Sucesso! Faça o login novamente, redirecionando...");
        setAlertType("Success");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const handleCancelChanges = () => {
    navigate(-1);
  };

  const handleShowKey = () => {
    setShowKey(!showKey);
  };

  useEffect(() => {
    document.title = "Editar usuário";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleCancelChanges}
        text="Tem certeza que deseja cancelar ?"
      />
      <BackButton />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Novo nome de usuário</Label>
        <Input
          disabled={loading}
          placeholder={"Digite aqui seu novo usuário"}
          {...register("userName")}
        />
        <SpanError>{errors.userName?.message}</SpanError>

        <Label>Chave do Administrador</Label>
        <InputWrapper>
          <Input
            disabled={loading}
            type={showKey ? "text" : "password"}
            placeholder="*********"
            {...register("key")}
          />
          <ShowEyeButton type="button" onClick={handleShowKey}>
            {showKey ? <EyeOffIcon /> : <EyeOnIcon />}
          </ShowEyeButton>
        </InputWrapper>
        <SpanError>{errors.key?.message}</SpanError>

        <ButtonWrapper>
          <Button disabled={loading} type="button" onClick={handleOpenModal}>
            Cancelar
          </Button>
          <Button className="confirm" disabled={loading} type="submit">
            Salvar
          </Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
}
