import { createContext, useReducer, useContext, Dispatch } from "react";

import { PatientAction, PatientState, PatientReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

interface PatientContextProps {
  Patients: Array<PatientState> | [];
  dispatch: Dispatch<PatientAction>;
}

export const PatientContext = createContext<PatientContextProps | undefined>(undefined);

export const PatientContextProvider = (props: Props) => {
  const initialState: Array<PatientState> = [];
  const [state, dispatch] = useReducer(PatientReducer, initialState);

  return (
    <PatientContext.Provider value={{ Patients: state, dispatch }}>
      {props.children}
    </PatientContext.Provider>
  );
};

export const usePatientContext = (): PatientContextProps => {
  const context = useContext(PatientContext);

  if (!context) {
    throw new Error("Ocorreu um erro ao inicializar pacientes...");
  }

  return context;
};
