import styled from "styled-components";

export const Container = styled.dialog`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  ::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const ContentWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin-top: 3rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.light_red};
    }
  }

  &:active {
    svg path {
      stroke: ${({ theme }) => theme.colors.dark_red};
    }
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
`;

export const Button = styled.button`
  width: 100px;
  background-color: ${({ theme }) => theme.colors.gray};
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.light_gray};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.dark_gray};
  }

  &.confirm {
    background-color: ${({ theme }) => theme.colors.red};

    &:hover {
      background-color: ${({ theme }) => theme.colors.light_red};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.dark_red};
    }
  }
`;
