import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { Container, Form, Functions, Buttons, Button, Label, Span, Input } from "./style";

import { api } from "../../../../services/api";
import { Modal } from "../../../../components/Modal";
import { BackButton } from "../../../../components/BackButton";
import { AlertComponent } from "../../../../components/AlertComponent";
import { useWorkerContext } from "../../../../pages/Gamem/Workers/workerContext";
import { WorkerActionKind } from "../../../../pages/Gamem/Workers/workerReducer";

interface IErrorResponse {
  message: string;
}

interface WorkerData {
  id: number;
  email: string;
  function: Array<{
    id: string;
    name: string;
  }>;
  name: string;
  phone: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

const schema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Formato de email inválido")
    .required("Campo obrigatório"),
  phone: yup.string().min(16, "Número de telefone inválido").required("Campo obrigatório"),
  function: yup
    .array()
    .transform((value, originalValue) => {
      if (originalValue && Array.isArray(originalValue)) {
        return originalValue.filter((val) => typeof val === "string");
      }
      return value;
    })
    .min(1, "Selecione pelo menos uma função")
    .required(),
});

export function WorkersCreate() {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDialogElement>(null);
  const { dispatch } = useWorkerContext();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<WorkerData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<WorkerData> = (WorkerData) => {
    setLoading(true);
    const workerRequest = {
      name: WorkerData.name,
      email: WorkerData.email,
      phone: WorkerData.phone,
      functionNames: WorkerData.function,
    };
    api
      .post("/worker/create-worker", workerRequest)
      .then((response) => {
        showAlertMessage(WorkerData.name + " cadastrado com sucesso!");
        setAlertType("Success");
        updateWorker(response.data.id, response.data.function, WorkerData);
        setTimeout(() => {
          navigate("/trabalhadores");
        }, 1000);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const handleCancelChanges = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = "Cadastrar Trabalhador";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleCancelChanges}
        text="Tem certeza que deseja cancelar ?"
      />
      <BackButton />
      <h1>Cadastrar Trabalhador</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Nome</Label>
        <Input type="text" {...register("name")} placeholder="Digite aqui seu nome" />
        <Span>{errors?.name?.message}</Span>
        <Label>E-mail</Label>
        <Input type="text" {...register("email")} placeholder="Digite aqui seu email" />
        <Span>{errors?.email?.message}</Span>
        <Label>Telefone </Label>

        <InputMask
          type="text"
          mask="(99) 9 9999-9999"
          maskPlaceholder={null}
          onChange={(e) => {
            setValue("phone", e.target.value);
          }}
        >
          <Input placeholder="Digite aqui seu telefone" />
        </InputMask>
        <Span>{errors?.phone?.message}</Span>
        <Label>Função </Label>
        <Functions>
          <div>
            <Input type="checkbox" value="Atendente" {...register(`function.${0}`)} />
            <Label>Atendente</Label>
          </div>
          <div>
            <Input type="checkbox" value="Coordenador" {...register(`function.${1}`)} />
            <Label>Coordenador</Label>
          </div>
          <div>
            <Input type="checkbox" value="Magnetizador" {...register(`function.${2}`)} />
            <Label>Magnetizador</Label>
          </div>
        </Functions>
        <Span>{errors?.function?.message}</Span>
        <Buttons>
          <Button disabled={loading} type="button" onClick={handleOpenModal}>
            Cancelar
          </Button>
          <Button disabled={loading} type="submit" className="confirm">
            Cadastrar
          </Button>
        </Buttons>
      </Form>
    </Container>
  );
  function updateWorker(
    id: number,
    f: Array<{ id: string; name: string }>,
    WorkerData: WorkerData
  ) {
    const newWorker = {
      id: id,
      name: WorkerData.name,
      email: WorkerData.email,
      phone: WorkerData.phone,
      function: f,
    };

    dispatch({
      type: WorkerActionKind.ADD_WORKER,
      payload: newWorker,
    });
  }
}
