//import React, { useState, useEffect } from 'react';
import { Data, FormData } from "../interface";
import { useFormContext } from "react-hook-form";
import { WorkerState } from "../../../Workers/workerReducer";

import {
  TreatmentsSection,
  CardInfo,
  Info,
  Label,
  Span,
  ProceduresSection,
  Div,
  Textarea,
  Magnetizer,
  RateWeek,
  Procedure,
  SpanError,
  Select,
  SaveButton,
} from "../style";

interface Props {
  data: Data;
  magnetizer: Array<WorkerState> | undefined;
}

export function SalvarFicha({ data, magnetizer }: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();

  return (
    <section>
      <TreatmentsSection>
        <CardInfo>
          <Info>
            <Label>Entrevista :</Label>
            <Span>{data.turn}º Vez</Span>
          </Info>
          <Info>
            <Label>Tipo de Tratamento :</Label>
            <Span {...register("treatmentType")} defaultValue={data.treatmentType}>
              {data.treatmentType}
            </Span>
          </Info>
          <Info>
            <Label>Atendente Fraterno :</Label>
            <Span>{data.attendantName}</Span>
          </Info>
          <Info>
            <Label>Queixas e Sintomas :</Label>
            <Textarea defaultValue={data.symptoms} {...register("symptoms")} disabled></Textarea>
          </Info>
        </CardInfo>
      </TreatmentsSection>
      <ProceduresSection>
        <Div>
          <Magnetizer>
            <Label>Magnetizador</Label>
            <Select
              defaultValue={data.magnetizerName ? data.magnetizerName : ""}
              {...register("magnetizerName")}
            >
              <option disabled value="">
                Magnetizador
              </option>
              {magnetizer?.map((mag) => {
                return (
                  <option key={mag.id} value={mag.name}>
                    {mag.name}
                  </option>
                );
              })}
            </Select>
            <SpanError>{errors.magnetizerName?.message}</SpanError>
          </Magnetizer>
          <RateWeek>
            <Label>Nota da Semana</Label>
            <Select defaultValue={data.noteWeek ? data.noteWeek : ""} {...register("noteWeek")}>
              <option disabled value="">
                Nota
              </option>
              <option value={1}>1 - Péssimo</option>
              <option value={2}>2 - Ruim</option>
              <option value={3}>3 - Bom</option>
              <option value={4}>4 - Ótimo</option>
              <option value={5}>5 - Excelente</option>
            </Select>
            <SpanError>{errors.noteWeek?.message}</SpanError>
          </RateWeek>
        </Div>
        <Procedure>
          <Label>Procedimento Magnético</Label>
          <Textarea
            placeholder="Digite o procedimento"
            defaultValue={data.procedure}
            {...register("procedure")}
          />
          <SpanError>{errors.procedure?.message}</SpanError>
        </Procedure>
      </ProceduresSection>
      <SaveButton type="submit">Salvar ficha</SaveButton>
    </section>
  );
}
