import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  PatientInformation,
  Label,
  Span,
  Reasons,
  Select,
  ContentWrapper,
  CardsWrapper,
} from "./style";

import { api } from "../../../../services/api";
import { BackButton } from "../../../../components/BackButton";
import { AlertComponent } from "../../../../components/AlertComponent";
import { CardTreatment } from "../../../../components/CardTreatment";
import { CardTreatmentSkeleton } from "../../../../components/CardTreatment/CardTreatmentSkeleton";
import Skeleton from "react-loading-skeleton";

interface IErrorResponse {
  message: string;
}

interface Patient {
  id: number;
  name: string;
  phone: string;
  yearsOld: string;
}

interface Treatments {
  id: number;
  date: string;
  reason1: string;
  reason2: string;
  reason3: string;
  reason4: string;
  status: "CONCLUDED" | "CANCELED";
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

type Status = "ALL" | "CONCLUDED" | "CANCELED";

export function PatientInfo() {
  const { patientName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<Status>("ALL");
  const [patient, setPatient] = useState<Patient>();
  const [treatments, setTreatments] = useState<Array<Treatments>>();
  const [reasons, setReasons] = useState<Array<String>>();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const onCardClick = (TreatmentId: number) => {
    navigate(`/tratamentos/arquivados/${TreatmentId}/${patient?.name}`);
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  useEffect(() => {
    api
      .get("/patient/patient-information", {
        params: {
          patientName: patientName,
        },
      })
      .then((response) => {
        setPatient(response.data);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
  }, [patientName, navigate]);

  useEffect(() => {
    api
      .get("/patient/reasons-treatment", {
        params: {
          patientName: patientName,
        },
      })
      .then((response) => {
        setReasons(response.data);
        setIsLoading(false);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  }, [patientName]);

  useEffect(() => {
    if (patient) {
      api
        .get("/patient/treatments-archived", {
          params: {
            patientId: patient?.id,
          },
        })
        .then((response) => {
          setTreatments(response.data);
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    }
  }, [patient]);

  useEffect(() => {
    document.title = "Paciente";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <BackButton />
      <PatientInformation>
        <Label>Nome</Label>
        <Span>{isLoading ? <Skeleton width={250} /> : patient?.name}</Span>
        <Label>Telefone</Label>
        <Span>{isLoading ? <Skeleton width={100} /> : patient?.phone}</Span>
        <Label>Idade</Label>
        <Span>{isLoading ? <Skeleton width={50} /> : patient?.yearsOld}</Span>
        <Label>Motivos de Tratamento</Label>
        <Reasons>
          {isLoading ? (
            <Skeleton count={4} width={200} />
          ) : reasons && reasons.length > 0 ? (
            reasons.map((item, index) => {
              return <Span key={index}>{item}</Span>;
            })
          ) : (
            <Span>Não há tratamento ativo.</Span>
          )}
        </Reasons>
      </PatientInformation>
      <ContentWrapper>
        <Label>Tratamentos anteriores</Label>
        <Select defaultValue={"ALL"} onChange={(e) => setStatus(e.target.value as Status)}>
          <option value="ALL">Todos</option>
          <option value="CONCLUDED">Concluídos</option>
          <option value="CANCELED">Cancelados</option>
        </Select>

        <CardsWrapper>
          {isLoading ? (
            <>
              {[...Array(4)].map((_, index) => (
                <CardTreatmentSkeleton key={index} />
              ))}
            </>
          ) : status !== "ALL" ? (
            treatments &&
            treatments
              .filter((item) => item.status === status)
              .map((item, index) => (
                <CardTreatment onClick={() => onCardClick(item.id)} key={index} data={item} />
              ))
          ) : (
            treatments &&
            treatments.map((item, index) => (
              <CardTreatment onClick={() => onCardClick(item.id)} key={index} data={item} />
            ))
          )}
        </CardsWrapper>
      </ContentWrapper>
    </Container>
  );
}
