export enum ListActionKind {
  GET_PATIENTNAME = "GET_PATIENTNAME",
  GET_TREATMENTNAME = "GET_TREATMENTNAME",
}

export interface ListAction {
  type: ListActionKind;
  payload: Array<string>;
}

export interface ListState {
  patients: Array<string>;
  treatments: Array<string>;
}

export const ListReducer = (state: ListState, action: ListAction) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_PATIENTNAME":
      // Preenche a lista com os nomes dos pacientes
      // if (payload) {
      return {
        ...state,
        patients: payload || [],
      };
    // }
    // return state;
    case "GET_TREATMENTNAME":
      // Preenche a lista com os nomes dos pacientes com tratamentos em progresso
      // if (payload) {
      return {
        ...state,
        treatments: payload || [],
      };
    // }
    // return state;
    default:
      return state;
  }
};
