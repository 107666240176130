import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 250px;
  margin-top: 1rem;
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;
