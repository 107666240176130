import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { AxiosError } from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Table,
  THead,
  TBody,
  TRow,
  TCell,
  Actions,
  Button,
  ContentWrapper,
} from "./style";

import { ReactComponent as CheckIcon } from "../../../assets/icons/Check.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/icons/Archive.svg";

import { api } from "../../../services/api";
import { Modal } from "../../../components/Modal";
import { SearchBar } from "../../../components/SearchBar";
import { Pagination } from "../../../components/Pagination";
import { AlertComponent } from "../../../components/AlertComponent";
import { formatDate } from "../../../hooks/formatDate";

interface IErrorResponse {
  message: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

interface TreatmentListed {
  currentPage: number;
  offset: number;
  pageNumber: number;
  pageSize: number;
  patientListedResponse: Array<{
    id: number;
    lastTreatment: string;
    lastTreatmentWrite: string;
    name: string;
  }>;
  totalElements: number;
  totalPages: number;
}

const LIMIT = 10;

export function Treatments() {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [treatmentListed, setTreatmentListed] = useState<TreatmentListed>({} as TreatmentListed);
  const [selectedTreatment, setSelectedTreatment] = useState<number>(0);
  const [status, setStatus] = useState<"CONCLUDED" | "CANCELED">("CONCLUDED");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const handleSearch = (value: string) => {
    navigate(`/tratamentos/${value}`);
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  const viewTreatment = (patientName: string) => {
    navigate(`/tratamentos/${patientName}`);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };
  const actionTreatment = (patientId: number) => {
    setSelectedTreatment(patientId);
    handleOpenModal();
  };

  const handleConfirm = () => {
    finishTreatment();
  };

  const finishTreatment = () => {
    api
      .get("/patient/finalized-treatment", {
        params: {
          patientId: selectedTreatment,
          status: status,
        },
      })
      .then((response) => {
        showAlertMessage(response.data);
        setAlertType("Success");
        removeTreatmentFromList(selectedTreatment);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  };

  useEffect(() => {
    const getTreatmentListed = async () => {
      await api
        .get("/patient/treatment-listed", {
          params: {
            page: currentPage,
            size: LIMIT,
          },
        })
        .then((response) => {
          setTreatmentListed(response.data);
          setIsLoading(false);
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    };
    getTreatmentListed();
  }, [currentPage]);

  useEffect(() => {
    document.title = "Tratamentos";
  }, []);
  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleConfirm}
        text={
          status === "CONCLUDED"
            ? "Tem certeza que deseja concluir tratamento ?"
            : "Tem certeza que deseja cancelar tratamento ?"
        }
      />
      <SearchBar type="Treatment" onSearch={handleSearch} />
      <ContentWrapper>
        <Table>
          <THead>
            <TRow>
              <TCell>Nome</TCell>
              <TCell>Último Atendimento</TCell>
              <TCell className="not-show"></TCell>
              <TCell className="actions" style={{ width: "100px" }}>
                Ações
              </TCell>
            </TRow>
          </THead>
          <TBody>
            {isLoading ? (
              <>
                {[...Array(10)].map((_, index) => (
                  <TRow key={index}>
                    <TCell>
                      <Skeleton />
                    </TCell>
                    <TCell>
                      <Skeleton />
                    </TCell>
                    <TCell className="not-show">
                      <Skeleton />
                    </TCell>
                    <TCell>
                      <Skeleton />
                    </TCell>
                  </TRow>
                ))}
              </>
            ) : (
              treatmentListed?.patientListedResponse.map((patient) => {
                return (
                  <TRow key={patient.name}>
                    <TCell
                      onClick={() => {
                        viewTreatment(patient.name);
                      }}
                    >
                      {patient.name}
                    </TCell>
                    <TCell
                      onClick={() => {
                        viewTreatment(patient.name);
                      }}
                      className="lastTreatment-mobile"
                    >
                      {formatDate(patient.lastTreatment, "DD:MM")}
                    </TCell>
                    <TCell
                      onClick={() => {
                        viewTreatment(patient.name);
                      }}
                      className="lastTreatment"
                    >
                      {formatDate(patient.lastTreatment)}
                    </TCell>
                    <TCell
                      onClick={() => {
                        viewTreatment(patient.name);
                      }}
                      className="not-show"
                    >
                      {patient.lastTreatmentWrite}
                    </TCell>
                    <TCell className="actions">
                      <Actions>
                        <Button
                          type="button"
                          onClick={() => {
                            setStatus("CONCLUDED");
                            actionTreatment(patient.id);
                          }}
                        >
                          <CheckIcon />
                        </Button>
                        <Button
                          className="archive"
                          type="button"
                          onClick={() => {
                            setStatus("CANCELED");
                            actionTreatment(patient.id);
                          }}
                        >
                          <ArchiveIcon />
                        </Button>
                      </Actions>
                    </TCell>
                  </TRow>
                );
              })
            )}
          </TBody>
        </Table>
        {treatmentListed && (
          <Pagination
            total={treatmentListed?.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ContentWrapper>
    </Container>
  );

  function removeTreatmentFromList(selectedTreatment: number) {
    const newTreatmentList = treatmentListed.patientListedResponse.filter(
      (item) => item.id !== selectedTreatment
    );
    setTreatmentListed({ ...treatmentListed, patientListedResponse: newTreatmentList });
  }
}
