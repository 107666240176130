import { formatDate } from "../../hooks/formatDate";
import { Container, Header, Label, Span, Reasons } from "./style";

interface Data {
  data: Props;
  onClick: () => void;
}

interface Props {
  status: "CONCLUDED" | "CANCELED";
  date: string;
  reason1: string;
  reason2: string;
  reason3: string;
  reason4: string;
}

export function CardTreatment({ data, onClick }: Data) {
  return (
    <Container onClick={onClick}>
      <Header>
        <Label status={data.status}>
          {data.status === "CONCLUDED" ? "Concluído" : "Cancelado"}
        </Label>
        <Span>{formatDate(data.date)}</Span>
      </Header>
      <Label>Motivos de Tratamento</Label>
      <Reasons>
        <Span>{data.reason1}</Span>
        <Span>{data.reason2}</Span>
        <Span>{data.reason3}</Span>
        <Span>{data.reason4}</Span>
      </Reasons>
    </Container>
  );
}
