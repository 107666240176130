import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Form, Label, InputWrapper, Input, Button } from "./style";

import { ReactComponent as UpdateIcon } from "../../../assets/icons/Update.svg";

export function Settings() {
  const navigate = useNavigate();

  const UpdateUsername = () => {
    navigate("/configuracoes/editar-usuario");
  };
  const UpdatePassword = () => {
    navigate("/configuracoes/editar-senha");
  };

  useEffect(() => {
    document.title = "Configurações";
  }, []);

  return (
    <Container>
      <h1>Editar Perfil</h1>
      <Form>
        <Label>Usuário</Label>
        <InputWrapper>
          <Input disabled type="text" placeholder={`${localStorage.getItem("@gamem:username")}`} />
          <Button type="button" onClick={UpdateUsername}>
            <UpdateIcon />
          </Button>
        </InputWrapper>
        <Label>Senha</Label>
        <InputWrapper>
          <Input disabled type="password" placeholder="*********" />
          <Button type="button" onClick={UpdatePassword}>
            <UpdateIcon />
          </Button>
        </InputWrapper>
      </Form>
    </Container>
  );
}
