import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SectionLogo = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue};
  filter: drop-shadow(4px 0px 16px rgba(0, 0, 0, 0.25));
  border-radius: 0;

  img {
    max-width: 768px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    padding: 2rem;

    @media (max-width: 768px) {
      max-width: 400px;
      background-color: ${({ theme }) => theme.colors.white};
      filter: none;
    }
  }

  @media (max-width: 768px) {
    height: 30%;
    background-color: ${({ theme }) => theme.colors.white};
    filter: none;
  }
`;

export const SectionForm = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.white};

  form {
    max-width: 500px;
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    h1 {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    label {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }

    input {
      width: 100%;
      padding: 1rem;
    }

    button {
      width: 100%;
      height: 4rem;
      font-size: 24px;
      font-weight: 500;
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
      transition: 0.5s;
      :hover {
        background-color: ${({ theme }) => theme.colors.light_blue};
      }

      :active {
        background-color: ${({ theme }) => theme.colors.dark_blue};
      }

      :disabled {
        background-color: ${({ theme }) => theme.colors.gray};
      }
    }

    span {
      color: ${({ theme }) => theme.colors.red};
    }

    div {
      width: 100%;
      display: flex;
      position: relative;
      button {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        transition: 0.5s;
        background: none;
        :hover {
          background-color: ${({ theme }) => theme.colors.gray};
        }

        :active {
          background-color: ${({ theme }) => theme.colors.dark_gray};
        }
      }
    }
  }
`;
