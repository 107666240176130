import { Container, ContentWrapper, CloseButton, Text, Actions, Button } from "./style";

import { ReactComponent as XIcon } from "../../assets/icons/X.svg";

interface Props {
  text: string;
  handleConfirm: () => void;
  modalRef: React.RefObject<HTMLDialogElement>;
}

export const Modal = ({ text, handleConfirm, modalRef }: Props) => {
  const handleClose = () => {
    if (modalRef.current) {
      modalRef.current.close();
    }
  };

  return (
    <Container ref={modalRef}>
      <ContentWrapper>
        <CloseButton type="button" onClick={handleClose}>
          <XIcon />
        </CloseButton>
        <Text>{text}</Text>
        <Actions>
          <Button type="button" onClick={handleClose}>
            Não
          </Button>
          <Button
            className="confirm"
            type="button"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Sim
          </Button>
        </Actions>
      </ContentWrapper>
    </Container>
  );
};
