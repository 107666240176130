import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  z-index: 0;
`;

export const PatientForm = styled.form`
  padding: 2rem;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1em;

  @media (max-width: 600px) {
    padding: 1.5rem;
    grid-template-columns: 1fr;
  }
`;

export const FirstInterview = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  label {
    width: max-content;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`;

export const List = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 16em;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};

  z-index: 1;
  filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.2));
`;

export const Item = styled.li`
  cursor: pointer;
  padding: 0.25rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const Label = styled.label`
  width: 100%;
`;

export const Check = styled.input`
  cursor: pointer;
`;

export const Attendant = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Select = styled.select`
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const PatientInformation = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 500;
`;

export const SpanError = styled.span`
  color: ${({ theme }) => theme.colors.Error};
`;

export const TreatmentReasons = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const Reasons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 0;

  input:disabled {
    z-index: -1;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.border};
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`;

export const ComplaintsAndSymptoms = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 500;
  resize: none;
  max-height: 20rem;
  overflow-y: auto;
`;

export const TypeOfTreatment = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const Types = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  place-items: center;
  align-items: flex-start;
`;

export const Radio = styled.input`
  cursor: pointer;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};

  :hover {
    background-color: ${({ theme }) => theme.colors.light_blue};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.dark_blue};
  }
  :disabled {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;
