//import React, { useState, useEffect } from 'react';
import { Data } from "../interface";

import {
  TreatmentsSection,
  CardInfo,
  Info,
  Label,
  Span,
  ProceduresSection,
  Div,
  Textarea,
  Magnetizer,
  RateWeek,
  Procedure,
  SaveButton,
} from "../style";

interface Props {
  data: Data;
  onClick: () => void;
}

export function FichaSalva({ data, onClick }: Props) {
  return (
    <section>
      <TreatmentsSection>
        <CardInfo>
          <Info>
            <Label>Entrevista :</Label>
            <Span>{data.turn}º Vez</Span>
          </Info>
          <Info>
            <Label>Tipo de Tratamento :</Label>
            <Span>{data.treatmentType}</Span>
          </Info>
          <Info>
            <Label>Atendente Fraterno :</Label>
            <Span>{data.attendantName}</Span>
          </Info>
          <Info>
            <Label>Queixas e Sintomas :</Label>
            <Span>{data.symptoms}</Span>
          </Info>
        </CardInfo>
      </TreatmentsSection>
      <ProceduresSection>
        <Div>
          <Magnetizer>
            <Label>Magnetizador</Label>
            <Span>{data.magnetizerName}</Span>
          </Magnetizer>
          <RateWeek>
            <Label>Nota da Semana</Label>
            <Span>{data.noteWeek}</Span>
          </RateWeek>
        </Div>
        <Procedure>
          <Label>Procedimento Magnético</Label>
          <Span>{data.procedure}</Span>
        </Procedure>
      </ProceduresSection>
      <SaveButton type="button" onClick={onClick}>
        Editar ficha
      </SaveButton>
    </section>
  );
}
