import { NavLink, useNavigate } from "react-router-dom";
import { Container, Nav } from "./style";

import { ReactComponent as ListChecksIcon } from "../../assets/icons/ListChecks.svg";
import { ReactComponent as NotePencilIcon } from "../../assets/icons/NotePencil.svg";
import { ReactComponent as TreatmentIcon } from "../../assets/icons/Treatment.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/User.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/Settings.svg";
import { ReactComponent as SignOutIcon } from "../../assets/icons/SignOut.svg";

import { useMenu } from "../../contexts/menuContext";
import { Modal } from "../../components/Modal";
import { useRef } from "react";

export function Menu() {
  const { isShown, toggleIsShown } = useMenu();
  const modalRef = useRef<HTMLDialogElement>(null);
  const navigate = useNavigate();

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      toggleIsShown();
    }
  };

  const logOut = () => {
    handleOpenModal();
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem("@gamem:token");
    localStorage.removeItem("@gamem:username");
    navigate("/");
  };

  return (
    <Container className={isShown ? "isShown" : "not_isShown"}>
      <Modal
        modalRef={modalRef}
        handleConfirm={handleLogOut}
        text="Tem certeza que deseja sair ?"
      />
      <Nav onClick={handleClick}>
        <NavLink to="/formulario" className={({ isActive }) => (isActive ? "active" : "")}>
          <ListChecksIcon />
          Formulário
        </NavLink>
        <NavLink to="/pacientes" className={({ isActive }) => (isActive ? "active" : "")}>
          <NotePencilIcon />
          Pacientes
        </NavLink>
        <NavLink to="/tratamentos" className={({ isActive }) => (isActive ? "active" : "")}>
          <TreatmentIcon />
          Tratamentos
        </NavLink>
        <NavLink to="/trabalhadores" className={({ isActive }) => (isActive ? "active" : "")}>
          <UserIcon />
          Trabalhadores
        </NavLink>
        <NavLink to="/configuracoes" className={({ isActive }) => (isActive ? "active" : "")}>
          <SettingsIcon />
          Configurações
        </NavLink>
        <NavLink
          onClick={(event) => {
            event.preventDefault();
            logOut();
          }}
          to={"/"}
        >
          <SignOutIcon />
          Sair
        </NavLink>
      </Nav>
    </Container>
  );
}
