import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.text};
  border-bottom: 2px solid ${({ theme }) => theme.colors.border};

  &:hover {
    color: ${({ theme }) => theme.colors.light_blue};
    border-color: ${({ theme }) => theme.colors.light_blue};

    svg path {
      stroke: ${({ theme }) => theme.colors.light_blue};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.dark_blue};
    border-color: ${({ theme }) => theme.colors.dark_blue};

    svg path {
      stroke: ${({ theme }) => theme.colors.dark_blue};
    }
  }
`;
