import * as yup from "yup";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import Logo from "../../../assets/images/gamem_logo.png";
import { ReactComponent as EyeOnIcon } from "../../../assets/icons/EyeOn.svg";
import { ReactComponent as EyeOffIcon } from "../../../assets/icons/EyeOff.svg";
import { Container, SectionLogo, SectionForm } from "./style";

import { AlertComponent } from "../../../components/AlertComponent";

interface IErrorResponse {
  message: string;
}

interface loginData {
  userName: string;
  password: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

const schema = yup.object().shape({
  userName: yup.string().required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
});

export function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<loginData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<loginData> = (loginData) => {
    setLoading(true);
    const loginRequest = {
      userName: loginData.userName,
      password: loginData.password,
    };
    api
      .post("/login/auth", loginRequest)
      .then((response) => {
        localStorage.setItem("@gamem:token", response.data.token);
        localStorage.setItem("@gamem:username", response.data.username);
        showAlertMessage("Usuário logado com sucesso!");
        setAlertType("Success");
        setTimeout(() => {
          navigate("/formulario");
        }, 1000);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };

  useEffect(() => {
    document.title = "GAMEM";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <SectionLogo>
        <img src={Logo} alt="Logo da GAMEM (Grupo de Atendimento Magnético Espíria Mesmer)" />
      </SectionLogo>
      <SectionForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Entrar</h1>
          <label>Usuário</label>
          <input
            disabled={loading}
            type="text"
            placeholder="Digite aqui seu usuário"
            {...register("userName")}
          />
          <span>{"*" && errors.userName?.message}</span>
          <label>Senha</label>
          <div>
            <input
              disabled={loading}
              type={showPassword ? "text" : "password"}
              placeholder="Digite aqui sua senha"
              {...register("password")}
            />
            <button type="button" onClick={handleShowPassword}>
              {showPassword ? <EyeOffIcon /> : <EyeOnIcon />}
            </button>
          </div>
          <span>{"*" && errors.password?.message}</span>
          <button type="submit" disabled={loading}>
            Clique para entrar
          </button>
        </form>
      </SectionForm>
    </Container>
  );
}
