import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
`;
export const PatientInformation = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

export const Reasons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Select = styled.select``;

export const ContentWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
