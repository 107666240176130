export enum WorkerActionKind {
  SET_WORKER = "SET_WORKER",
  ADD_WORKER = "ADD_WORKER",
  DELETE_WORKER = "DELETE_WORKER",
  UPDATE_WORKER = "UPDATE_WORKER",
}

export interface WorkerAction {
  type: WorkerActionKind;
  payload: Array<WorkerState> | WorkerState;
}

export interface WorkerState {
  id: number;
  name: string;
  email: string;
  phone: string;
  function: Array<{
    id: string;
    name: string;
  }>;
}

export const workerReducer = (state: Array<WorkerState> | [], action: WorkerAction) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_WORKER":
      // Preenche a lista com os trabalhadores
      if (Array.isArray(payload)) {
        return payload;
      }
      return state;
    case "ADD_WORKER":
      // Adicionar um trabalhador à lista
      if (!Array.isArray(payload)) {
        return [...state, payload];
      }
      return state;
    case "DELETE_WORKER":
      // Remover um trabalhador da lista
      if (!Array.isArray(payload)) {
        return state.filter((item) => item.id !== payload.id);
      }
      return state;
    case "UPDATE_WORKER":
      // Atualizar dados de um trabalhador
      if (!Array.isArray(payload)) {
        return state.map((item) => (item.id === payload.id ? payload : item));
      }
      return state;
    default:
      return state;
  }
};
