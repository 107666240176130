import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1rem;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  z-index: 100;
  div {
    width: 100%;
    height: 100%;
    text-align: center;
    img {
      max-width: 100px;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }

  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: ${({ theme }) => theme.colors.light_gray};
    }

    :active {
      svg path {
        stroke: ${({ theme }) => theme.colors.light_gray};
      }
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
`;
