import Skeleton from "react-loading-skeleton";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Table,
  THead,
  TBody,
  TRow,
  TCell,
  Actions,
  Button,
  ContentWrapper,
} from "./style";

import { api } from "../../../services/api";
import { SearchBar } from "../../../components/SearchBar";
import { Pagination } from "../../../components/Pagination";
import { AlertComponent } from "../../../components/AlertComponent";

import { ReactComponent as ExpandIcon } from "../../../assets/icons/Expand.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash.svg";
import { Modal } from "../../../components/Modal";
import { PatientActionKind } from "./reducer";
import { usePatientContext } from "./context";

interface IErrorResponse {
  message: string;
}

interface PatientsInfo {
  currentPage: number;
  offset: number;
  pageNumber: number;
  pageSize: number;
  patientList: Array<{
    id: number;
    name: string;
    phone: string;
  }>;
  totalElements: number;
  totalPages: number;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

export function Patient() {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDialogElement>(null);
  const { Patients, dispatch } = usePatientContext();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [patientsInfo, setPatientsInfo] = useState<PatientsInfo>();
  const [selectedPatient, setSelectedPatient] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const handleSearch = (value: string) => {
    navigate(`/pacientes/${value}`);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const viewPatient = (patientName: string) => {
    navigate(`/pacientes/${patientName}`);
  };

  const deletePatient = (id: number) => {
    handleOpenModal();
    setSelectedPatient(id);
  };

  const handleDeletePatient = () => {
    api
      .delete("/patient/delete-patient", {
        params: {
          patientId: selectedPatient,
        },
      })
      .then((response) => {
        dispatch({
          type: PatientActionKind.DELETE_PATIENT,
          payload: selectedPatient,
        });
        showAlertMessage(response.data);
        setAlertType("Success");
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  useEffect(() => {
    function onChangePatientsList() {
      api
        .get("/patient/information-contact", {
          params: {
            page: currentPage,
            size: 10,
          },
        })
        .then((response) => {
          if (response.data.patientList.length < 1) {
            return setCurrentPage(currentPage - 1);
          }
          dispatch({
            type: PatientActionKind.SET_PATIENT,
            payload: response.data.patientList,
          });
          setPatientsInfo(response.data);
          setIsLoading(false);
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    }
    onChangePatientsList();
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (Patients) {
      setIsLoading(false);
    }
  }, [Patients]);

  useEffect(() => {
    document.title = "Pacientes";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleDeletePatient}
        text="Tem certeza que deseja excluir paciente ?"
      />
      <SearchBar type="Patient" onSearch={handleSearch} />
      <ContentWrapper>
        <Table>
          <THead>
            <TRow>
              <TCell>Nome</TCell>
              <TCell className="phone">Telefone</TCell>
              <TCell className="actions" style={{ width: "100px" }}>
                Ações
              </TCell>
            </TRow>
          </THead>
          <TBody>
            {isLoading ? (
              <>
                {[...Array(10)].map((_, index) => (
                  <TRow key={index}>
                    <TCell>
                      <Skeleton />
                    </TCell>
                    <TCell className="phone">
                      <Skeleton />
                    </TCell>
                    <TCell className="actions" style={{ width: "100px" }}>
                      <Skeleton />
                    </TCell>
                  </TRow>
                ))}
              </>
            ) : (
              Patients?.map((patient) => {
                return (
                  <TRow key={`${patient.id}`}>
                    <TCell
                      onClick={() => {
                        viewPatient(patient.name);
                      }}
                    >
                      {patient.name}
                    </TCell>
                    <TCell
                      className="phone"
                      onClick={() => {
                        viewPatient(patient.name);
                      }}
                    >
                      {patient.phone}
                    </TCell>
                    <TCell className="actions">
                      <Actions>
                        <Button
                          type="button"
                          onClick={() => {
                            viewPatient(patient.name);
                          }}
                        >
                          <ExpandIcon />
                        </Button>
                        <Button
                          className="trash"
                          type="button"
                          onClick={() => {
                            deletePatient(patient.id);
                          }}
                        >
                          <TrashIcon />
                        </Button>
                      </Actions>
                    </TCell>
                  </TRow>
                );
              })
            )}
          </TBody>
        </Table>
        {patientsInfo && (
          <Pagination
            total={patientsInfo?.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ContentWrapper>
    </Container>
  );
}
