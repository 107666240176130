import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Header = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .backbutton {
    button {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    button {
      display: none;
    }
    gap: 0;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 1rem;
  }
`;

export const Patient = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  padding: 1rem;

  .backbutton {
    button {
      margin: 0;
    }
    @media (min-width: 600px) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0.5rem;
  }
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

export const SpanError = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.Error};
`;

export const Textarea = styled.textarea`
  width: 100%;
  max-height: 20rem;
  font-size: 18px;
  font-weight: 500;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.text};
  resize: none;
  :disabled {
    background: none;
    border: none;
  }
`;

export const TreatmentReasons = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Reasons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0.5rem;
  }
`;

export const CardWrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const DateList = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 0;
  select {
    max-width: max-content;
  }
  text-align: end;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 1rem;
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Magnetizer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Select = styled.select``;

export const RateWeek = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Procedure = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const GraphicWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
`;
