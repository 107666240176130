import styled from "styled-components";

export const Container = styled.section`
  max-width: 90%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg:first-of-type {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
  svg:last-of-type {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    :hover path {
      stroke: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem 3rem;
  font-size: 18px;
  font-weight: 500;
`;

export const Button = styled.button`
  @media (max-width: 480px) {
    display: none;
  }
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  :hover {
    background-color: ${({ theme }) => theme.colors.light_blue};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.dark_blue};
  }
`;

export const List = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 16em;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};

  z-index: 1;
  filter: drop-shadow(0px 10px 12px rgba(0, 0, 0, 0.2));
`;

export const Item = styled.li`
  cursor: pointer;
  padding: 0.25rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
