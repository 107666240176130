import { ThemeProvider } from "styled-components";
import Router from "./routes";

import { GlobalStyle } from "./styles/global";
import { defaultTheme } from "./styles/default";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Router />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
