import { Routes, Route, BrowserRouter } from "react-router-dom";

import { AllContextProvider } from "./contexts/AllContextProvider";

import { DefaultLayout } from "./layouts/DefaultLayout";
import { SignIn } from "./pages/Gamem/SignIn";
import { Form } from "./pages/Gamem/Form";
import { Patient } from "./pages/Gamem/Patient";
import { PatientInfo } from "./pages/Gamem/Patient/PatientInfo";
import { Treatments } from "./pages/Gamem/Treatments";
import { TreatmentInfo } from "./pages/Gamem/Treatments/TreatmentInfo";
import { TreatmentArchives } from "./pages/Gamem/Treatments/TreatmentArchives";
import { Workers } from "./pages/Gamem/Workers";
import { WorkersUpdate } from "./pages/Gamem/Workers/WorkersUpdate";
import { WorkersCreate } from "./pages/Gamem/Workers/WorkersCreate";
import { WorkersInfo } from "./pages/Gamem/Workers/WorkersInfo";
import { Settings } from "./pages/Gamem/Settings";
import { SettingsUsername } from "./pages/Gamem/Settings/SettingsUsername";
import { SettingsPassword } from "./pages/Gamem/Settings/SettingsPassword";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />}></Route>
        <Route
          element={
            <AllContextProvider>
              <DefaultLayout />
            </AllContextProvider>
          }
        >
          <Route path="/formulario" element={<Form />}></Route>
          <Route path="/pacientes" element={<Patient />}></Route>
          <Route path="/pacientes/:patientName" element={<PatientInfo />}></Route>
          <Route path="/tratamentos" element={<Treatments />}></Route>
          <Route path="/tratamentos/:patientName" element={<TreatmentInfo />}></Route>
          <Route
            path="/tratamentos/arquivados/:id/:patientName"
            element={<TreatmentArchives />}
          ></Route>
          <Route path="/trabalhadores" element={<Workers />}></Route>
          <Route path="/trabalhadores/:id" element={<WorkersInfo />}></Route>
          <Route path="/trabalhadores/editar/:id" element={<WorkersUpdate />}></Route>
          <Route path="/trabalhadores/cadastro" element={<WorkersCreate />}></Route>
          <Route path="/configuracoes" element={<Settings />}></Route>
          <Route path="/configuracoes/editar-usuario" element={<SettingsUsername />}></Route>
          <Route path="/configuracoes/editar-senha" element={<SettingsPassword />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
