import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-left: 4rem;

  h1 {
    font-size: 32px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin: 0 2rem;
    margin-top: 1rem;
  }
`;

export const Form = styled.section`
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin: 0.5rem 0;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 18px;

  color: #b6b6bb;
  background-color: #e9e9ef;
`;

export const Button = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  svg path {
    stroke: ${({ theme }) => theme.colors.black};
  }

  :hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }
  :active {
    svg path {
      stroke: ${({ theme }) => theme.colors.dark_blue};
    }
  }
`;
