import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { api } from "../../../../services/api";
import { AlertComponent } from "../../../../components/AlertComponent";
import { BackButton } from "../../../../components/BackButton";
import { SearchBar } from "../../../../components/SearchBar";
import { Pagination } from "../../../../components/Pagination";

import {
  Container,
  Header,
  InfoWrapper,
  Patient,
  Info,
  Label,
  Span,
  Textarea,
  TreatmentReasons,
  Reasons,
  CardWrapper,
  DateList,
  Div,
  Card,
  CardInfo,
  Magnetizer,
  Select,
  RateWeek,
  Procedure,
  GraphicWrapper,
} from "./style";
import { Graphic } from "../../../../components/Graphic";
import { formatDate } from "../../../../hooks/formatDate";
import Skeleton from "react-loading-skeleton";

interface IErrorResponse {
  message: string;
}

interface Treatment {
  cardPaged: {
    cardList: Array<CardProps>;
    size: number;
    totalElements: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    currentPage: number;
    offset: number;
  };
  dateList: Array<string>;
  id: number;
  lastTreatment: string;
  reason1: string;
  reason2: string;
  reason3: string;
  reason4: string;
  status: "PROGRESS" | "CONCLUDED" | "CANCELED";
}

interface CardProps {
  attendantName: string;
  date: string;
  editable: boolean;
  id: number;
  magnetizerName: string;
  noteWeek: number;
  procedure: string;
  symptoms: string;
  treatmentType: "M1" | "M2";
  turn: number;
}

interface GraphicData {
  date: string;
  noteWeek: number;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

export function TreatmentArchives() {
  const navigate = useNavigate();
  const { id, patientName } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberWeeks, setNumberWeeks] = useState(4);
  const [graphicData, setGraphicData] = useState<Array<GraphicData>>();
  const [cardDate, setCardDate] = useState<string>();
  const [treatment, setTreatment] = useState<Treatment>();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");

  const handleSearch = (value: string) => {
    navigate(`/tratamentos/${value}`);
  };

  const handleSelectCardByDate = (Value: string) => {
    const dateIndex = treatment?.dateList.findIndex((value) => value === Value);
    if (dateIndex !== undefined) {
      setCurrentPage(dateIndex);
    }
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  useEffect(() => {
    api
      .get("/patient/treatment-id", {
        params: {
          treatmentId: id,
          size: 1,
          page: currentPage,
        },
      })
      .then((response) => {
        setTreatment(response.data);
        setCardDate(response.data.dateList[currentPage]);
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
  }, [id, currentPage, navigate]);

  useEffect(() => {
    if (cardDate) {
      api
        .get("/patient/card-by-date", {
          params: {
            date: cardDate,
            treatmentId: treatment?.id,
          },
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    }
  }, [treatment?.id, cardDate]);

  useEffect(() => {
    if (treatment?.id) {
      api
        .get("/patient/treatment-graphic", {
          params: {
            numberWeeks: numberWeeks,
            treatmentId: treatment?.id,
          },
        })
        .then((response) => {
          setGraphicData(response.data);
        })
        .catch((error: AxiosError<IErrorResponse>) => {
          if (error.response) {
            showAlertMessage(error.response.data.message);
            setAlertType("Error");
          }
        });
    }
  }, [treatment?.id, numberWeeks]);

  useEffect(() => {
    document.title = "Arquivados";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Header>
        <div className="backbutton">
          <BackButton />
        </div>
        <SearchBar type="Treatment" onSearch={handleSearch} />
      </Header>
      <InfoWrapper>
        <Patient>
          <div className="backbutton">
            <BackButton />
          </div>
          <Info>
            <Label>Nome</Label>
            {!treatment ? <Skeleton /> : <Span>{patientName}</Span>}
          </Info>
          <Info>
            <Label>Último Atendimento</Label>
            {!treatment ? <Skeleton /> : <Span>{formatDate(treatment?.lastTreatment)}</Span>}
          </Info>
        </Patient>
        <TreatmentReasons>
          <Label>Motivo do Tratamento:</Label>
          <Reasons>
            {!treatment ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                <Span>{treatment?.reason1}</Span>
                <Span>{treatment?.reason2}</Span>
                <Span>{treatment?.reason3}</Span>
                <Span>{treatment?.reason4}</Span>
              </>
            )}
          </Reasons>
        </TreatmentReasons>
      </InfoWrapper>
      <CardWrapper>
        <DateList>
          <Select value={cardDate} onChange={(e) => handleSelectCardByDate(e.target.value)}>
            {treatment?.dateList.map((date) => {
              return (
                <option key={date} value={date}>
                  {formatDate(date)}
                </option>
              );
            })}
          </Select>
        </DateList>
        {treatment?.cardPaged.cardList.map((card, index) => {
          return (
            <Card key={index}>
              <CardInfo>
                <Info>
                  <Label>Entrevista :</Label>
                  {!treatment ? <Skeleton /> : <Span>{card.turn}º Vez</Span>}
                </Info>
                <Info>
                  <Label>Tipo de Tratamento :</Label>
                  {!treatment ? <Skeleton /> : <Span>{card.treatmentType}</Span>}
                </Info>
                <Info>
                  <Label>Atendente Fraterno :</Label>
                  {!treatment ? <Skeleton /> : <Span>{card.attendantName}</Span>}
                </Info>
                <Info>
                  <Label>Queixas e Sintomas :</Label>
                  {!treatment ? (
                    <div>
                      <Skeleton count={2} />
                      <Skeleton count={1} width={"75%"} />
                    </div>
                  ) : (
                    <Textarea defaultValue={card.symptoms} disabled></Textarea>
                  )}
                </Info>
              </CardInfo>
              <Div>
                <Magnetizer>
                  <Label>Magnetizador</Label>
                  <Span>{card.magnetizerName}</Span>
                </Magnetizer>
                <RateWeek>
                  <Label>Nota da Semana</Label>
                  <Span>{card.noteWeek}</Span>
                </RateWeek>
              </Div>
              <Procedure>
                <Label>Procedimento Magnético</Label>
                <Textarea disabled defaultValue={card.procedure}></Textarea>
              </Procedure>
            </Card>
          );
        })}
        {treatment && (
          <Pagination
            total={treatment?.cardPaged.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardWrapper>
      <GraphicWrapper>
        <Select
          style={{ maxWidth: "10rem" }}
          defaultValue={4}
          onChange={(e) => {
            setNumberWeeks(parseInt(e.target.value));
          }}
        >
          <option value={4}>4 Semanas</option>
          <option value={8}>8 Semanas</option>
          <option value={12}>12 Semanas</option>
        </Select>
        {graphicData && <Graphic data={graphicData} />}
      </GraphicWrapper>
    </Container>
  );
}
