//import React, { useState, useEffect } from 'react';
import { Container } from "./style";
import Logo_gamem from "../../assets/images/gamem_logo.png";
import Logo_eventos from "../../assets/images/gamem_eventos_logo.png";
import { ReactComponent as MenuIcon } from "../../assets/icons/Menu.svg";
import { useMenu } from "../../contexts/menuContext";

export function Header() {
  const { toggleIsShown } = useMenu();
  const handleClick = () => {
    toggleIsShown();
  };
  const role = localStorage.getItem("@gamem:role");

  return (
    <Container>
      <button type="button" onClick={handleClick}>
        <MenuIcon />
      </button>
      <div>
        {role === "EVENTOS" ? (
          <img src={Logo_eventos} alt="Logo do GAMEM EVENTOS" />
        ) : (
          <img
            src={Logo_gamem}
            alt="Logo do GAMEM (Grupo de Atendimento Magnético Espíria Mesmer)"
          />
        )}
      </div>
    </Container>
  );
}
