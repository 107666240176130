import React, { useContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

interface ContextProps {
  isShown: Boolean;
  toggleIsShown: () => void;
}

export const MenuContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const MenuContextProvider = ({ children }: Props) => {
  const [isShown, setIsShown] = useState(false);

  const toggleIsShown = () => {
    setIsShown(!isShown);
  };

  return (
    <MenuContext.Provider value={{ isShown, toggleIsShown }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
