export const formatDate = (date: string, format?: string) => {
  if (format === "DD:MM") {
    let dates: Array<string> = date.split("-");
    let formattedDates = dates[2] + "/" + dates[1];
    return formattedDates;
  }

  let dates: Array<string> = date.split("-");
  let formattedDates = dates[2] + "/" + dates[1] + "/" + dates[0];
  return formattedDates;
};
