import { SkeletonTheme } from "react-loading-skeleton";
import { ListContextProvider } from "./listContext";
import { WorkerContextProvider } from "../pages/Gamem/Workers/workerContext";
import { MenuContextProvider } from "./menuContext";
import { PatientContextProvider } from "../pages/Gamem/Patient/context";

interface Props {
  children: React.ReactNode;
}

export const AllContextProvider = ({ children }: Props) => (
  <SkeletonTheme baseColor="rgb(163, 184, 194, 0.7)" highlightColor="rgb(240, 243, 245, 0.7)">
    <MenuContextProvider>
      <WorkerContextProvider>
        <ListContextProvider>
          <PatientContextProvider>{children}</PatientContextProvider>
        </ListContextProvider>
      </WorkerContextProvider>
    </MenuContextProvider>
  </SkeletonTheme>
);
