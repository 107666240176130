import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWorkerContext } from "../../../../pages/Gamem/Workers/workerContext";
import { Container, Label, Span, Functions } from "./style";

import { BackButton } from "../../../../components/BackButton";

export function WorkersInfo() {
  const { workers } = useWorkerContext();
  const { id } = useParams();

  const worker = workers?.find((worker) => worker.id.toString() === id);

  const roles = [""];

  worker?.function?.forEach((item) => {
    roles.push(item.name);
  });

  useEffect(() => {
    document.title = "Trabalhador";
  }, []);

  return (
    <Container>
      <BackButton />
      <Label>Nome</Label>
      <Span>{worker?.name}</Span>
      <Label>E-mail</Label>
      <Span>{worker?.email}</Span>
      <Label>Telefone </Label>
      <Span>{worker?.phone}</Span>
      <Label>Função </Label>
      <Functions>
        {worker?.function?.map((item) => {
          return <Span key={item.id}>{item.name}</Span>;
        })}
      </Functions>
    </Container>
  );
}
