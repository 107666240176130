import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header";
import { Menu } from "../../components/Menu";

import { Container, Body } from "./style";

export function DefaultLayout() {
  return (
    <Container>
      <Header />
      <Body>
        <Menu />
        <Outlet />
      </Body>
    </Container>
  );
}
