import { AxiosError } from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Table, THead, TBody, TRow, TCell, ActionButton, Span, Button } from "./style";

import { ReactComponent as UpdateIcon } from "../../../assets/icons/Update.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash.svg";

import { api } from "../../../services/api";
import { WorkerActionKind } from "./workerReducer";
import { Modal } from "../../../components/Modal";
import { AlertComponent } from "../../../components/AlertComponent";
import { useWorkerContext } from "./workerContext";

interface IErrorResponse {
  message: string;
}

type AlertType = "Success" | "Error" | "Alert" | "Information";

export function Workers() {
  const navigate = useNavigate();
  const { workers, dispatch } = useWorkerContext();
  const modalRef = useRef<HTMLDialogElement>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("Success");
  const [selectedWorkerId, setSelectedWorkerId] = useState<number>(0);

  const createWorker = () => {
    navigate("/trabalhadores/cadastro");
  };

  const viewWorker = (ID: number) => {
    navigate(`/trabalhadores/${ID}`);
  };

  const updateWorker = (ID: number) => {
    navigate(`/trabalhadores/editar/${ID}`);
  };

  const deleteWorker = (ID: number) => {
    handleOpenModal();
    setSelectedWorkerId(ID);
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 4000);
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const handleDeleteWorker = () => {
    const selectedWorker = workers?.find((worker) => worker.id === selectedWorkerId);
    api
      .delete("/worker/delete-worker", {
        params: {
          id: selectedWorkerId,
        },
      })
      .then((response) => {
        showAlertMessage(response.data);
        setAlertType("Success");
        if (selectedWorker) {
          dispatch({
            type: WorkerActionKind.DELETE_WORKER,
            payload: selectedWorker,
          });
        }
      })
      .catch((error: AxiosError<IErrorResponse>) => {
        if (error.response) {
          showAlertMessage(error.response.data.message);
          setAlertType("Error");
        }
      });
  };

  useEffect(() => {
    document.title = "Trabalhadores";
  }, []);

  return (
    <Container>
      <AlertComponent type={alertType} message={alertMessage} id="alertComponent" />
      <Modal
        modalRef={modalRef}
        handleConfirm={handleDeleteWorker}
        text="Tem certeza que deseja excluir trabalhador ?"
      />
      <Button type="button" onClick={createWorker}>
        Cadastrar Trabalhador
      </Button>
      <Table>
        <THead>
          <TRow>
            <TCell>Nome</TCell>
            <TCell className="email">E-mail</TCell>
            <TCell className="phone">Telefone</TCell>
            <TCell className="function">Função</TCell>
            <TCell>Ações</TCell>
          </TRow>
        </THead>
        <TBody>
          {workers?.map((worker) => {
            return (
              <TRow key={`${worker.id}`}>
                <TCell
                  onClick={() => {
                    viewWorker(worker.id);
                  }}
                >
                  {worker.name}
                </TCell>
                <TCell
                  className="email"
                  onClick={() => {
                    viewWorker(worker.id);
                  }}
                >
                  {worker.email}
                </TCell>
                <TCell
                  className="phone"
                  onClick={() => {
                    viewWorker(worker.id);
                  }}
                >
                  {worker.phone}
                </TCell>
                <TCell
                  className="function"
                  onClick={() => {
                    viewWorker(worker.id);
                  }}
                >
                  {worker?.function?.map((item) => {
                    return <Span key={`${item.id}`}>{item.name}</Span>;
                  })}
                </TCell>
                <TCell>
                  <ActionButton
                    type="button"
                    onClick={() => {
                      updateWorker(worker.id);
                    }}
                  >
                    <UpdateIcon />
                  </ActionButton>
                  <ActionButton
                    className="trash"
                    type="button"
                    onClick={() => {
                      deleteWorker(worker.id);
                    }}
                  >
                    <TrashIcon />
                  </ActionButton>
                </TCell>
              </TRow>
            );
          })}
        </TBody>
      </Table>
    </Container>
  );
}
