import { createContext, useReducer, useContext, useEffect, Dispatch } from "react";
import { api } from "../services/api";
import { ListAction, ListActionKind, ListReducer } from "./listReducer";

interface Props {
  children: React.ReactNode;
}

interface ListContextProps {
  patients: Array<string> | [];
  treatments: Array<string> | [];
  dispatch: Dispatch<ListAction>;
}

export const ListContext = createContext<ListContextProps | undefined>(undefined);

export const ListContextProvider = (props: Props) => {
  const initialState = {
    patients: [],
    treatments: [],
  };
  const [state, dispatch] = useReducer(ListReducer, initialState);

  useEffect(() => {
    const getPatientsName = async () => {
      await api
        .get("/patient/names")
        .then((response) => {
          dispatch({
            type: ListActionKind.GET_PATIENTNAME,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error) {
            alert("Ocorreu um erro ao encontrar nomes de pacientes... " + error);
            window.location.href = "/";
          }
        });
    };

    getPatientsName();
  }, []);

  useEffect(() => {
    const getTreatmentsName = async () => {
      await api
        .get("/patient/list-name-in-treatment")
        .then((response) => {
          dispatch({
            type: ListActionKind.GET_TREATMENTNAME,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error) {
            alert("Ocorreu um erro ao encontrar tratamentos em progresso... " + error);
            window.location.href = "/";
          }
        });
    };

    getTreatmentsName();
  }, []);

  return (
    <ListContext.Provider
      value={{ patients: state.patients, treatments: state.treatments, dispatch }}
    >
      {props.children}
    </ListContext.Provider>
  );
};

export const useListContext = (): ListContextProps => {
  const context = useContext(ListContext);

  if (!context) {
    throw new Error("Ocorreu um erro ao listar pacientes e/ou tratamentos...");
  }

  return context;
};
