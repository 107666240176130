import styled from "styled-components";

interface LabelProps {
  status?: "CONCLUDED" | "CANCELED";
}

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 0.25rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.label<LabelProps>`
  font-size: 18px;
  font-weight: 500;
  border-radius: 0.25rem;
  padding: ${({ status }) => (status ? "0 1rem" : "0")};
  color: ${({ theme, status }) =>
    status ? theme.colors.white : theme.colors.black};
  background-color: ${({ theme, status }) =>
    status === "CONCLUDED"
      ? theme.colors.Success
      : status === "CANCELED"
      ? theme.colors.Error
      : ""};
`;

export const Span = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

export const Reasons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
