import { createContext, useReducer, useContext, useEffect, Dispatch } from "react";
import { api } from "../../../services/api";

import { WorkerAction, WorkerActionKind, WorkerState, workerReducer } from "./workerReducer";

interface Props {
  children: React.ReactNode;
}

interface WorkerContextProps {
  workers: Array<WorkerState> | [];
  dispatch: Dispatch<WorkerAction>;
}

export const WorkerContext = createContext<WorkerContextProps | undefined>(undefined);

export const WorkerContextProvider = (props: Props) => {
  const initialState: Array<WorkerState> = [];
  const [state, dispatch] = useReducer(workerReducer, initialState);

  useEffect(() => {
    const getWorkerData = async () => {
      await api
        .get("/worker")
        .then((response) => {
          dispatch({
            type: WorkerActionKind.SET_WORKER,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error) {
            alert("Ocorreu um erro ao inicializar trabalhadores... " + error);
            window.location.href = "/";
          }
        });
    };

    getWorkerData();
  }, []);

  return (
    <WorkerContext.Provider value={{ workers: state, dispatch }}>
      {props.children}
    </WorkerContext.Provider>
  );
};

export const useWorkerContext = (): WorkerContextProps => {
  const context = useContext(WorkerContext);

  if (!context) {
    throw new Error("Ocorreu um erro ao inicializar trabalhadores...");
  }

  return context;
};
