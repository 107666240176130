import { useNavigate } from "react-router-dom";

import { Container } from "./style";

import { ReactComponent as ArrowBackIcon } from "../../assets/icons/ArrowBack.svg";

export function BackButton() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container onClick={goBack}>
      <ArrowBackIcon />
      Voltar
    </Container>
  );
}
