import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-left: 4rem;

  @media (max-width: 768px) {
    margin: 0 2rem;
    margin-top: 1rem;
  }
`;

export const Form = styled.form`
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin: 0.5rem 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 18px;
`;

export const SpanError = styled.span`
  color: ${({ theme }) => theme.colors.Error};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  color: ${({ theme }) => theme.colors.red};
  border: 1px solid ${({ theme }) => theme.colors.red};
  :hover {
    color: ${({ theme }) => theme.colors.light_red};
    border-color: ${({ theme }) => theme.colors.light_red};
  }
  :active {
    color: ${({ theme }) => theme.colors.dark_red};
    border-color: ${({ theme }) => theme.colors.dark_red};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.gray};
    border-color: ${({ theme }) => theme.colors.gray};
  }

  &.confirm {
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};

    :hover {
      color: ${({ theme }) => theme.colors.light_blue};
      border-color: ${({ theme }) => theme.colors.light_blue};
    }
    :active {
      color: ${({ theme }) => theme.colors.dark_blue};
      border-color: ${({ theme }) => theme.colors.dark_blue};
    }
    :disabled {
      color: ${({ theme }) => theme.colors.gray};
      border-color: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const ShowEyeButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.5s;
  background: none;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.dark_gray};
  }
`;
